<template>
  <div class="discount-new">
    <div class="font-weight-bold mb-3">
      <router-link class="disabled--text" to="/trip-discount-codes">Trip discount codes</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">Create new trip discount</span>
    </div>
    <v-card outlined min-height="70vh" color="d-flex flex-column">
      <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
        <div class="d-flex flex-md-row flex-column">
          <div class="me-md-3 width50">
            <div class="font-weight-bold mb-3">Trip discount code</div>
            <v-text-field
              class="field46"
              v-model="data.discount_code"
              placeholder="Enter code"
              :error-messages="codeErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-text-field>
          </div>
          <div class="ms-md-3 width50">
            <div class="font-weight-bold mb-3">Usage limit</div>
            <v-text-field
              class="field46"
              v-model="data.usage_count_limit"
              placeholder="Enter usage limit"
              :error-messages="limitErrors"
              type="number"
              hint="0 for unlimited usage"
              persistent-hint
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-text-field>
          </div>
        </div>
        <div class="font-weight-bold mb-3">Company name</div>
        <v-text-field
          class="field46"
          v-model="data.company_name"
          placeholder="Company name"
          :error-messages="companyErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="font-weight-bold mb-3">Trip</div>
        <v-autocomplete
          class="field46"
          v-model="data.trip"
          placeholder="Select trip"
          :error-messages="tripErrors"
          :items="tripList"
          item-text="title"
          item-value="id"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-autocomplete>
        <div class="font-weight-bold mb-3">Discount</div>
        <div class="d-flex flex-md-row flex-column v-input">
          <div class="me-md-3 width100">
            <div class="text-body-2 font-weight-bold mb-1">Discount type</div>
            <v-select
              class="field46"
              v-model="data.discount_type"
              placeholder="Select type"
              :error-messages="typeErrors"
              :items="typeList"
              outlined
              dense
              color="primary"
              height="46"
            >
              <template v-slot:selection="data">
                <span class="text-capitalize">{{ data.item }}</span>
              </template>
              <template v-slot:item="data">
                <span class="text-capitalize">{{ data.item }}</span>
              </template>
            </v-select>
          </div>
          <div class="ms-md-3 width100" v-if="data.discount_type">
            <div v-if="data.discount_type == 'fixed'" class="text-body-2 font-weight-bold mb-1">Amount, SAR</div>
            <div v-if="data.discount_type == 'percent'" class="text-body-2 font-weight-bold mb-1">Percent of the total, %</div>
            <v-text-field
              class="field46"
              v-model="data.discount_value"
              placeholder="Enter amount"
              :error-messages="valueErrors"
              outlined
              dense
              color="primary"
              height="46"
            ></v-text-field>
          </div>
        </div>
        <div class="font-weight-bold mb-3">Availability dates</div>
        <div class="d-flex flex-md-row flex-column v-input">
          <vc-date-picker
            locale="en"
            :input-debounce="500"
            v-model="data.available_since"
            :masks="masks"
            :model-config="modelConfig"
            :first-day-of-week="2"
            is-required
            :min-date="new Date()"
            color="blue"
            class="me-md-3 width100"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                class="field46"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="From"
                :error-messages="fromErrors"
                outlined
                dense
                color="primary"
                height="46"
                readonly
              >
                <template v-slot:append>
                  <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                </template>
              </v-text-field>
            </template>
          </vc-date-picker>
          <vc-date-picker
            locale="en"
            :input-debounce="500"
            v-model="data.available_till"
            :masks="masks"
            :model-config="modelConfig"
            :first-day-of-week="2"
            is-required
            :min-date="data.available_since || new Date()"
            color="blue"
            class="ms-md-3 width100"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                class="field46"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="To"
                :error-messages="toErrors"
                outlined
                dense
                color="primary"
                height="46"
                readonly
              >
                <template v-slot:append>
                  <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                </template>
              </v-text-field>
            </template>
          </vc-date-picker>
        </div>
        <v-checkbox color="success" class="mt-0 check" v-model="data.tabby_authorization_required">
          <template v-slot:label>
            <div class="ms-2">
              <div class="black--text">Requires Tabby authorization</div>
              <div class="text-body-2 text--text font-weight-bold">
                Set this flag in case the discount code should be applied only in case the customer uses Tabby payment to cover Trip booking
                amount (fully or partially)
              </div>
            </div>
          </template>
        </v-checkbox>
      </div>
      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <div class="text-end py-3 px-sm-10 px-3">
        <v-btn @click="createItem" depressed class="rounded primary text-body-2" height="30" width="106">Create</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      data: {
        tabby_authorization_required: false,
      },
      masks: {
        input: 'MMM D, YYYY',
      },
      modelConfig: {
        type: 'number',
        mask: 'iso',
      },
      typeList: ['fixed', 'percent'],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getTripsShort');
  },
  methods: {
    notifi(btn) {
      if (btn == 'done') {
        this.$notify({
          title: 'Done!',
          message: 'Discount code was successfully created',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'error') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async createItem() {
      this.error = [];
      this.data.discount_value ? (this.data.discount_value = this.data.discount_value * 100) : '';
      await this.$store
        .dispatch('createDiscountCode', this.data)
        .then(() => {
          this.notifi('done');
        })
        .catch((e) => {
          this.data.discount_value ? (this.data.discount_value = this.data.discount_value / 100) : '';
          this.error = e.response.data.error;
          e.response.status > 401 ? this.notifi('error') : '';
        });
    },
  },
  computed: {
    tripList() {
      return this.$store.getters.tripsShort;
    },
    companyErrors() {
      const errors = [];
      this.error.find((item) => item == 'company_name__required') && errors.push('Please enter company name');
      this.error.find((item) => item == 'company_name__invalid') && errors.push('Provided company name is not valid');
      return errors;
    },
    codeErrors() {
      const errors = [];
      this.error.find((item) => item == 'discount_code__required') && errors.push('Please enter code');
      this.error.find((item) => item == 'discount_code__invalid') && errors.push('Provided code is not valid');
      this.error.find((item) => item == 'discount_code__exists') && errors.push('Sorry, discount code already exists');
      return errors;
    },
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'discount_type__required') && errors.push('Please select discount type');
      this.error.find((item) => item == 'discount_type__invalid') && errors.push('Selected discount type is not valid');
      return errors;
    },
    valueErrors() {
      const errors = [];
      this.error.find((item) => item == 'discount_value__required') && errors.push('Please enter discount value');
      this.error.find((item) => item == 'discount_value__invalid') && errors.push('Provided discount value is not valid');
      return errors;
    },
    limitErrors() {
      const errors = [];
      this.error.find((item) => item == 'usage_count_limit__required') && errors.push('Please enter usage limit');
      this.error.find((item) => item == 'usage_count_limit__invalid') && errors.push('Provided usage limit is not valid');
      return errors;
    },
    tripErrors() {
      const errors = [];
      this.error.find((item) => item == 'trip__required') && errors.push('Please select trip');
      this.error.find((item) => item == 'trip__invalid') && errors.push('Selected trip is not valid');
      return errors;
    },
    amountErrors() {
      const errors = [];
      this.error.find((item) => item == 'amount__required') && errors.push('Please enter amount');
      this.error.find((item) => item == 'amount__invalid') && errors.push('Provided amount is not valid');
      return errors;
    },
    fromErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_since__required') && errors.push('Please enter available since');
      this.error.find((item) => item == 'available_since__invalid') && errors.push('Provided available since is not valid');
      return errors;
    },
    toErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_till__required') && errors.push('Please enter available till');
      this.error.find((item) => item == 'available_till__invalid') && errors.push('Provided available till is not valid');
      this.error.find((item) => item == 'available_till__before_available_since') &&
        errors.push('Provided available till is less then available since');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setTripsShort');
  },
};
</script>

<style lang="scss">
#app .discount-new {
  .v-input {
    max-width: 371px;
  }
  .check.v-input {
    max-width: initial;
    .v-input__slot {
      align-items: initial;
    }
  }
}
</style>
